import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png';
import MatrixBackground from './MatrixBackground';

function App() {
  const [text, setText] = useState('');
  const [showLogo, setShowLogo] = useState(false);
  const fullText = 'ASTUTE MACHINES';

  useEffect(() => {
    setTimeout(() => setShowLogo(true), 500);

    setTimeout(() => {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex <= fullText.length) {
          setText(fullText.slice(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 200);

      return () => clearInterval(interval);
    }, 1500);
  }, []);

  return (
    <div className="App">
      <MatrixBackground />
      <img src={logo} alt="Logo" className={`logo ${showLogo ? 'visible' : ''}`} />
      <h1 className="robotic-text">{text}<span className="cursor">|</span></h1>
    </div>
  );
}

export default App;